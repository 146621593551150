<template>
    <div>
        <van-nav-bar title="查看日报" left-text="返回" @click-left="back" fixed left-arrow/>
        
        <div class="login_form">
            <van-calendar
            :show-title="false"
            :poppable="false"
            :min-date="minDate"
            :show-confirm="false"
            :formatter="formatter"
            :style="{ height: tableHeight+'px' }"
            :className="'van-calendar__top-info'"
            @select="selectDate"
            />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                hasWaiting: false,
                state: 0,
                user: JSON.parse(localStorage.userInfo),
                minDate: new Date(2020, 0, 1),
                maxDate: new Date(new Date().getFullYear(),new Date().getMonth()+1,new Date().getDate()),
                currentDate: new Date(),
                nowTime: this.format(new Date(new Date()),"yyyy-MM-dd"),
                showPicker: false,
                report: [],
                statusTxt:["待审核", "已通过", "已驳回", "待提交"],
                statusStyle:["waiting", "filledReportStyle", "RejectStyle", ""],
                tableHeight:0,
            };
        },
        created() {
            let height = window.innerHeight;
            this.tableHeight = height - 46;
            const that = this;
            window.onresize = function temp() {
                that.tableHeight = window.innerHeight - 46;
            };
        },
        methods: {
            selectDate(value) {
                console.log(this.format(value));
                sessionStorage.targetDate = this.format(value);
                this.$router.push('/view');
            },
            formatter(day) {
                const month = day.date.getMonth() + 1;
                const date = day.date.getDate();
                if (this.format(day.date) == this.nowTime) {
                    day.text = "今天";
                }
                //找到那一天的数据状态
                let targetDate = this.format(day.date);
                this.report.forEach(r=>{
                    if (r.createDate == targetDate) {
                        if (r.missReport) {
                            day.bottomInfo = '漏填';
                            day.className = 'RejectStyle';
                        } else {
                            day.bottomInfo = this.statusTxt[r.state];
                            day.className = this.statusStyle[r.state];
                        }
                    }
                })
                
                
                return day;
                },
            // 返回
            back() {
                history.back();
            },

             // 时间转换
            format(date, pattern) {
                pattern = pattern || "yyyy-MM-dd";
                var _this = this;
                return pattern.replace(/([yMdhsm])(\1*)/g, function ($0) {
                    switch ($0.charAt(0)) {
                        case 'y': return _this.padding(date.getFullYear(), $0.length);
                        case 'M': return _this.padding(date.getMonth() + 1, $0.length);
                        case 'd': return _this.padding(date.getDate(), $0.length);
                        case 'w': return date.getDay() + 1;
                        case 'h': return _this.padding(date.getHours(), $0.length);
                        case 'm': return _this.padding(date.getMinutes(), $0.length);
                        case 's': return _this.padding(date.getSeconds(), $0.length);
                    }
                });
            },

            padding(s, len) {
                var len = len - (s + '').length;
                for (var i = 0; i < len; i++) { s = '0' + s; }
                return s;
            },

            // 改变时间
            changeTime(time) {
                this.nowTime = this.format(new Date(time),"yyyy-MM-dd");
                this.currentDate = time;
                this.showPicker = false;
                this.getReport();
            },

            // 获取日报
            getReport() {
                this.hasWaiting = false;
                const toast = this.$toast.loading({
                    forbidClick: true,
                    duration: 0
                });
                var startDate = new Date();
                startDate.setMonth(startDate.getMonth() -2);
                this.$axios.post("/report/getReportFillStatus", {startDate: this.format(startDate), endDate:this.format(this.maxDate), userId: this.user.id})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.clear();
                        this.report = res.data;
                        
                    } else {
                        this.$toast.clear();
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();});
            },
        },

        mounted() {
            this.getReport();
        }
    };
</script>

<style lang="less" scoped>
// 日历备注
.van-calendar__top-info {
  background: linear-gradient(86deg, rgba(212, 165, 116, 0.98), rgba(238, 202, 163, 0.98));
}


    .login_form {
        margin-top: 46px;
    }

    .one_report {
        margin-bottom: 15px;
        font-size:14px;
    }

    .form_text {
        margin: 15px 0 15px;
        padding: 0 12px;
    }
    
    .form_btn {
        text-align: right;
    }

    .form_btn button {
        margin-left: 10px;
    }

    .one_report_data {
        margin-bottom: 10px;
        padding: 0 22px;
        div {
            line-height: 30px;
        }
    }
</style>
<style >
.waiting {
    color:orange;
}
.filledReportStyle {
    color:#32CD32;

}
.RejectStyle {
    color:red;
}
.van-calendar__bottom-info {
    -webkit-transform: scale(0.8);
}
</style>
<style lang="less">
    .van-nav-bar .van-icon , .van-nav-bar__text {
        color: #20a0ff;
    }
    .button {
        float: right;
        width: 50px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        border: 1px solid red;
        color: red;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
